import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { LinkService } from '@app/core/services/link.service';
import { RouteHelperService } from '@app/core/services/route-helper.service';
import { WINDOW } from '@inshared/shared/util';
import { Observable, Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

/** @deprecated - please consider using outshared-lib version */
@Injectable({
    providedIn: 'root',
})
export class SeoService implements OnDestroy {
    public readonly routeData$: Observable<Data> = this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route: ActivatedRoute) => {
            while (route.firstChild) {
                route = route.firstChild;
            }
            return route;
        }),
        filter((route: ActivatedRoute) => route.outlet === 'primary'),
        mergeMap((route: ActivatedRoute) => route.data),
    );

    private get currentUrl(): string {
        return this.routeHelperService.getCleanUrl();
    }
    private previousData: Data = {};
    private routeDataSubscription: Subscription = new Subscription();
    private canonicalElement: HTMLLinkElement;

    public constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private title: Title,
        private meta: Meta,
        private linkService: LinkService,
        private routeHelperService: RouteHelperService,
        @Inject(WINDOW) private window: Window,
    ) { }

    public init(): void {
        const routeData = this.routeData$.subscribe((data: Data) => {
            this.previousData = data;
            if (data.hasOwnProperty('title')) {
                this.setTitle(data.title);
            }
            if (data.hasOwnProperty('description')) {
                this.updateTag('description', data.description);
            }
            if (data.hasOwnProperty('robots')) {
                this.updateTag('robots', data.robots);
            }

            if (this.isSubDomain(this.window.location.host)) {
                this.updateTag('robots', 'noindex');
            }

            this.canonicalElement = this.linkService.addTag(
                {
                    rel: 'canonical',
                    href: this.window.location.protocol + '//' + this.window.location.host + this.currentUrl,
                }
            );
        });
        this.routeDataSubscription.add(routeData);
    }

    public ngOnDestroy(): void {
        this.routeDataSubscription.unsubscribe();
        this.linkService.removeTagElement(this.canonicalElement);
    }

    public setTitle(title: string): void {
        this.title.setTitle(title);
    }

    public updateTag(name: string, content: string): void {
        this.meta.updateTag({ name, content });
    }

    public getPreviousData(): Data {
        return this.previousData;
    }

    private isSubDomain(url): boolean {
        // SubDomain test is allowed for testing reasons; Test cannot be indexed because its running in an not public env
        const whitelist: string[] = ['www', 'test', 'insh-nl-frontend-website', 'acceptatie'];
        const urlArray = url.split('.');
        const subdomain = urlArray[0];

        if (urlArray.length < 3) {
            return false;
        }

        return whitelist.findIndex((item) => item === subdomain) < 0;
    }
}
